/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import './App.scss';

// import { Lightning } from './lightning/lightning';
import { WeatherCalendar } from './pastWeather/calendar/calendar';
import MonthlySummary from './pastWeather/monthlySummary/monthlySummary';
import YearlySummary from './pastWeather/yearlySummary/yearlySummary';
import { AnnualClimateReport } from './pastWeather/annualClimateReport/annualClimateReport';
import CwopWeather from './cwopWeather/cwop-weather';
import CwopStation from './cwopWeather/cwop-station';
// import IndoorWeather from './indoor/indoor';
import AirQuality from './AirQuality/air-quality';
import HomePage from './home/home';
import Lightning from './lightning/lightning';
import Radar from './radar/radar';
import Heading from './heading/heading';

class App extends Component {
    render() {
        ReactGA.initialize('UA-40289031-1', { gaOptions: { cookieFlags: "SameSite=None;Secure" } });
        return (
            <div>
                <div className='overallContainer'>
                    <Heading />
                    <Route exact={true} path='/' component={HomePage} />
                    <Route exact={true} path='/Radar' component={Radar} />
                    <Route exact={true} path='/AirQuality' component={AirQuality} />
                    <Route exact={true} path='/Lightning' component={Lightning} />
                    <Route exact={true} path='/PastWeather/Calendar' component={WeatherCalendar} />
                    <Route exact={true} path='/PastWeather/MonthlySummary' component={MonthlySummary} />
                    <Route exact={true} path='/PastWeather/YearlySummary' component={YearlySummary} />
                    <Route exact={true} path='/PastWeather/AnnualClimateReport' component={AnnualClimateReport} />
                    <Route exact={true} path='/CWOPWeather' component={CwopWeather} />
                    <Route exact={true} path='/CWOPWeather/Station/:callSign?' component={CwopStation} />
                    {/* <Route exact={true} path='/Indoor/:location?' component={IndoorWeather} /> */}
                    <div className="clear"></div>
                </div>
            </div>
        );
    }
}

export default App;
