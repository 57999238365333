export function round(value, decimals) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

export function formatOnePlace(value) {
    if (value === undefined || value === null) {
        return '';
    } else {
        return value.toFixed(1);
    }
}

export function formatTwoPlaces(value) {
    if (value === undefined || value === null) {
        return '0.00';
    } else {
        return value.toFixed(2);
    }
}

export function formatThreePlaces(value) {
    if (value === undefined || value === null) {
        return '0.000';
    } else {
        return value.toFixed(3);
    }
}
