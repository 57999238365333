
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import ReactGA from 'react-ga';
import Griddle, { RowDefinition, ColumnDefinition } from 'griddle-react';
import { Chart, HighchartsChart, HighchartsProvider, LineSeries, Tooltip, XAxis, YAxis } from 'react-jsx-highcharts';
import Highcharts from 'highcharts';
import { TEMPERATURE } from '../../Utils/constants';
import { Calendar } from 'primereact/calendar';
import { Nullable } from 'primereact/ts-helpers';
import './monthlySummary.scss';
import { YearlySummaryDailyValues, YearlySummaryResponse } from '../../models/yearly-summary-response';

const MonthlySummary = () => {

  const [selectedDate, setSelectedDate] = useState<Nullable<Date>>(new Date());
  const [monthlyData, setMonthlyData] = useState<any[]>([]);
  const [highLowChartData, setHighLowChartData] = useState<any[]>([]);

  useEffect(() => {
    ReactGA.pageview('PastWeather/MonthlySummary');

    const getMonthSummaryData = (month: string, year: string) => {
      axios.get<YearlySummaryResponse>(`${process.env.REACT_APP_PAST_WEATHER_CONTROLLER_ROOT}GetMonthlySummaryData?month=${month}&year=${year}`)
        .then((response) => {
          if (response.data) {
            setMonthlyData(response.data.highLowValues);

            const chartData = [];
            const highs: number[][] = [];
            const lows: number[][] = [];
            response.data.highTemperatureValues.values.forEach((rec: YearlySummaryDailyValues) => {
              const time = moment.utc(rec.time).valueOf();
              highs.push([time, rec.value]);
            });

            response.data.lowTemperatureValues.values.forEach((rec: YearlySummaryDailyValues) => {
              const time = moment.utc(rec.time).valueOf();
              lows.push([time, rec.value]);
            });

            chartData.push({ data: highs, name: 'High Temperature', color: '#AC4F4C', yAxis: 'Temperature' });
            chartData.push({ data: lows, name: 'Low Temperature', color: '#0000FF', yAxis: 'Temperature' });

            setHighLowChartData(chartData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (selectedDate) {
      getMonthSummaryData(moment(selectedDate).format('M'), moment(selectedDate).format('YYYY'));
    }
  }, [selectedDate]);

  const gridLayout = ({ Table }: any) => (
    <div>
      <Table />
    </div>
  );

  const weatherTypeHeading = ({ title }: any) => <div className='weatherTypeHeader'>{title}</div>;
  const valueHeading = ({ title }: any) => <div className='valueHeader'>{title}</div>;
  const miscHeading = ({ title }: any) => <div className='miscHeader'>{title}</div>;
  const alignRightCell = ({ value }: any) => <div className='alignRight'>{value}</div>;

  return (
    <div className="monthlySummary">
      <div className="leftSideContainer">
        <Calendar
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.value)}
          view="month"
          dateFormat="mm/yyyy"
          minDate={new Date(2004, 7, 1)}
          maxDate={new Date()}
          inline
        />
      </div>
      <div className="weatherDataContainer">
        <div>
          <h2>{moment(selectedDate).format('MMMM YYYY')}</h2>
        </div>
        <div className="summaryContainer">
          <Griddle
            data={monthlyData}
            components={{ Layout: gridLayout }}>
            <RowDefinition>
              <ColumnDefinition id='weatherType' title='Weather Type' customHeadingComponent={weatherTypeHeading} />
              <ColumnDefinition id='displayValue' title='Value' customHeadingComponent={valueHeading} customComponent={alignRightCell} />
              <ColumnDefinition id='date' title='Date' customHeadingComponent={miscHeading} customComponent={alignRightCell} />
            </RowDefinition>
          </Griddle>
        </div>
        <div className="graphContainer">
          <HighchartsProvider Highcharts={Highcharts}>
            <HighchartsChart time={{ timezone: 'America/Denver' }}>
              <Chart height={210} width={470} />
              <Tooltip shared={true} />
              <XAxis
                type="datetime"
                labels={
                  {
                    style: {color: '#707073'}
                  }
                }
              />
              <YAxis id={TEMPERATURE}
                key={TEMPERATURE}
                labels={{
                  format: '{text}°',
                  style: {color: '#707073'}
                }}>
                {highLowChartData.map((graph) => {
                  return <LineSeries
                    key={graph.name}
                    data={graph.data}
                    name={graph.name}
                    color={graph.color}
                    marker={{ enabled: false }}
                    yAxis={graph.yAxis} />;
                })}
              </YAxis>

            </HighchartsChart>
          </HighchartsProvider>
        </div>
      </div>
    </div>
  );
}

export default MonthlySummary;