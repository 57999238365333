/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import ReactGA from 'react-ga';

import './annualClimateReport.css';

class AnnualClimateReport extends Component {
    
    constructor() {
        super();

        this.yearChanged = this.yearChanged.bind(this);
    }

    componentWillMount() {
        this.setState({
            selectedYear: moment().format('YYYY'),
            months: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
        });
        ReactGA.pageview('PastWeather/AnnualClimateReport');
    }

    componentDidMount() {
        const currentDate = moment();
        this.getYearData(currentDate.format('YYYY'));
    }

    getYearOptions() {
        let options = [];

        for (let i = parseInt(moment().format('YYYY'), 10); i >= 2004; i--)
        {
            options.push(<option key={'yearOption' + i} value={i}>{i}</option>);
        }

        return options;
    }

    padLeft(nr, n, str) {
        return Array(n-String(nr).length+1).join(str||' ')+nr;
    }

    getYearData(year) {
        var self = this;

        axios.get(process.env.REACT_APP_PAST_WEATHER_CONTROLLER_ROOT + 'GetAnnualClimateReport?year=' + year)
            .then (response => {
                if (response.data)
                {
                    self.setState({
                        yearlyTemperatureData: self.displayTemperatureData(response.data.temperatureData),
                        yearlyTemperatureSummaryData: self.displayTemperatureSummaryLineData(response.data.temperatureDataSummary),
                        yearlyPrecipitationData: self.displayPrecipitationData(response.data.rainData),
                        yearlyPrecipitationSummaryData: self.displayPrecipitationSummaryLineData(response.data.rainDataSummary),
                        yearlyWindData: self.displayWindData(response.data.windData),
                        yearlyWindSummaryData: self.displayWindSummaryLineData(response.data.windDataSummary),
                        yearlyHumidityData: self.displayHumidityData(response.data.humidityBarometerData),
                        yearlyHumiditySummaryData: self.displayHumiditySummaryLineData(response.data.humidityBarometerSummary),
                        yearlySolarData: self.displaySolarData(response.data.solarData),
                        yearlySolarSummaryData: self.displaySolarSummaryData(response.data.solarSummary)
                    });

                    
                }
            });
    }

    displayTemperatureData(temperatureData) {
        let tempDataString = '';

        if (temperatureData)
        {
            for (let i = 0; i < temperatureData.length; i++)
            {
                let rec = temperatureData[i];
                tempDataString += this.state.selectedYear 
                    + this.padLeft(rec.month, 3)
                    + this.padLeft(rec.maxMean.toFixed(1), 6)
                    + this.padLeft(rec.minMean.toFixed(1), 6)
                    + this.padLeft((rec.meanTotal / rec.meanCount).toFixed(1), 6)
                    + this.padLeft(rec.meanDiff.toFixed(1), 6)
                    + this.padLeft(rec.heatingDD.toFixed(1), 7)
                    + this.padLeft(rec.coolingDD.toFixed(1), 7)
                    + this.padLeft(rec.high.toFixed(1), 6)
                    + this.padLeft(rec.highDay, 6)
                    + this.padLeft(rec.low.toFixed(1), 6)
                    + this.padLeft(rec.lowDay, 6)
                    + this.padLeft(rec.max90, 6)
                    + this.padLeft(rec.max32, 5)
                    + this.padLeft(rec.min32, 5)
                    + this.padLeft(rec.min0, 4)
                    + `
    `;
            }
        }

        return tempDataString;
    }

    displayTemperatureSummaryLineData(temperatureSummaryData) {
        let tempDataString = '';

        if (temperatureSummaryData)
        {
            tempDataString += this.padLeft(temperatureSummaryData.maxMean.toFixed(1), 13)
                + this.padLeft(temperatureSummaryData.minMean.toFixed(1), 6)
                + this.padLeft((temperatureSummaryData.meanTotal / temperatureSummaryData.meanCount).toFixed(1), 6)
                + this.padLeft(temperatureSummaryData.meanDiff.toFixed(1), 6)
                + this.padLeft(temperatureSummaryData.heatingDD.toFixed(1), 7)
                + this.padLeft(temperatureSummaryData.coolingDD.toFixed(1), 7)
                + this.padLeft(temperatureSummaryData.high.toFixed(1), 6)
                + this.padLeft(this.state.months[temperatureSummaryData.highDay - 1], 6)
                + this.padLeft(temperatureSummaryData.low.toFixed(1), 6)
                + this.padLeft(this.state.months[temperatureSummaryData.lowDay - 1], 6)
                + this.padLeft(temperatureSummaryData.max90, 6)
                + this.padLeft(temperatureSummaryData.max32, 5)
                + this.padLeft(temperatureSummaryData.min32, 5)
                + this.padLeft(temperatureSummaryData.min0, 4)
                + `
    `;
        }

        return tempDataString;
    }

    displayPrecipitationData(precipData) {
        let precipDataString = '';

        if (precipData)
        {
            for (let i = 0; i < precipData.length; i++)
            {
                let rec = precipData[i];
                precipDataString += this.state.selectedYear 
                    + this.padLeft(rec.month, 3)
                    + this.padLeft(rec.totalRain.toFixed(2), 7)
                    + this.padLeft(rec.totalDiff.toFixed(2), 6)
                    + this.padLeft(rec.maxRain.toFixed(2), 6)
                    + this.padLeft(rec.maxRainDay, 6)
                    + this.padLeft(rec.overHundredeth, 4)
                    + this.padLeft(rec.overTenth, 5)
                    + this.padLeft(rec.overInch, 4)
                    + `
    `;
            }
        }

        return precipDataString;
    }

    displayPrecipitationSummaryLineData(precipData) {
        let precipDataString = '';

        if (precipData)
        {
            precipDataString += this.padLeft(precipData.totalRain.toFixed(2), 14)
                + this.padLeft(precipData.totalDiff.toFixed(2), 6)
                + this.padLeft(precipData.maxRain.toFixed(2), 6)
                + this.padLeft(this.state.months[precipData.maxRainDay - 1], 6)
                + this.padLeft(precipData.overHundredeth, 4)
                + this.padLeft(precipData.overTenth, 5)
                + this.padLeft(precipData.overInch, 4)
                + `
    `;
        }

        return precipDataString;
    }

    displayWindData(windData) {
        let windDataString = '';

        if (windData)
        {
            for (let i = 0; i < windData.length; i++)
            {
                let rec = windData[i];
                windDataString += this.state.selectedYear 
                    + this.padLeft(rec.month, 3)
                    + this.padLeft(rec.avgWindSpeed.toFixed(1), 7)
                    + this.padLeft(rec.highWindSpeed, 6)
                    + this.padLeft(rec.highWindDay, 6)
                    + this.padLeft(rec.direction, 5)
                    + `
    `;
            }
        }

        return windDataString;
    }

    displayWindSummaryLineData(windData) {
        let windDataString = '';

        if (windData)
        {
            windDataString += this.padLeft(windData.avgWindSpeed.toFixed(1), 14)
                + this.padLeft(windData.highWindSpeed, 6)
                + this.padLeft(this.state.months[windData.highWindDay - 1], 6)
                + this.padLeft(windData.direction, 5)
                + `
    `;
        }

        return windDataString;
    }

    displayHumidityData(humidityData) {
        let humidityDataString = '';

        if (humidityData)
        {
            for (let i = 0; i < humidityData.length; i++)
            {
                let rec = humidityData[i];
                humidityDataString += this.state.selectedYear 
                    + this.padLeft(rec.month, 3)
                    + this.padLeft(rec.humidityAverage.toFixed(1), 6)
                    + this.padLeft(rec.dewPointAverage.toFixed(1), 6)
                    + this.padLeft(rec.barometerAverage.toFixed(3), 8)
                    + this.padLeft(rec.barometerHigh.toFixed(3), 8)
                    + this.padLeft(rec.barometerHighDate, 6)
                    + this.padLeft(rec.barometerLow.toFixed(3), 8)
                    + this.padLeft(rec.barometerLowDate, 6)
                    + `
    `;
            }
        }

        return humidityDataString;
    }
    
    displayHumiditySummaryLineData(humidityData) {
        let humidityDataString = '';

        if (humidityData)
        {
            humidityDataString += this.padLeft(humidityData.humidityAverage.toFixed(1), 13)
                + this.padLeft(humidityData.dewPointAverage.toFixed(1), 6)
                + this.padLeft(humidityData.barometerAverage.toFixed(3), 8)
                + this.padLeft(humidityData.barometerHigh.toFixed(3), 8)
                + this.padLeft(this.state.months[humidityData.barometerHighDate - 1], 6)
                + this.padLeft(humidityData.barometerLow.toFixed(3), 8)
                + this.padLeft(this.state.months[humidityData.barometerLowDate - 1], 6)
                + `
    `;
        }

        return humidityDataString;
    }

    displaySolarData(solarData) {
        let solarDataString = '';

        if (solarData)
        {
            for (let i = 0; i < solarData.length; i++)
            {
                let rec = solarData[i];
                solarDataString += this.state.selectedYear 
                    + this.padLeft(rec.month, 3)
                    + this.padLeft(rec.solarEnergyAverage.toFixed(2), 8)
                    + this.padLeft(rec.solarEnergyTotal.toFixed(2), 11)
                    + this.padLeft(rec.solarRadiationHigh, 7)
                    + this.padLeft(rec.solarRadiationHighDay, 6)
                    + this.padLeft(rec.uvDoseAverage.toFixed(2), 7)
                    + this.padLeft(rec.uvDoseTotal.toFixed(2), 9)
                    + this.padLeft(rec.highUV.toFixed(1), 6)
                    + this.padLeft(rec.highUVDay, 6)
                    + `
    `;
            }
        }

        return solarDataString;
    }

    displaySolarSummaryData(solarData) {
        let solarDataString = '';

        if (solarData)
        {
            solarDataString += this.padLeft(solarData.solarEnergyAverage.toFixed(2), 15)
                + this.padLeft(solarData.solarEnergyTotal.toFixed(2), 11)
                + this.padLeft(solarData.solarRadiationHigh, 7)
                + this.padLeft(this.state.months[solarData.solarRadiationHighDay - 1], 6)
                + this.padLeft(solarData.uvDoseAverage.toFixed(2), 7)
                + this.padLeft(solarData.uvDoseTotal.toFixed(2), 9)
                + this.padLeft(solarData.highUV.toFixed(1), 6)
                + this.padLeft(this.state.months[solarData.highUVDay -1 ], 6)
                + `
    `;
        }

        return solarDataString;
    }

    yearChanged(e) {
        this.setState({selectedYear: e.target.value});
        this.getYearData(e.target.value);

        ReactGA.event({
            category: 'Past Weather',
            action: 'Annual Climate Report Year Changed',
            label: e.target.value
        });
    }

    render() {

        return (
            <div className='annualClimateReport'>
                <div>
                    <div className='annualReportDateDiv'>
                        Go to&nbsp;
                        <select id='yearSelect' value={this.state.selectedYear} onChange={this.yearChanged}>
                            { this.getYearOptions() }
                        </select>
                    </div>
                </div>
                <div className='noaaTextDiv' id='annualClimateReport'>
                    <div className='annualReportLongDiv' id='annualTemperatureReport'>
                        <pre>{`
                              ANNUAL CLIMATOLOGICAL SUMMARY FOR` + this.state.selectedYear + `
                     CITY: Highlands Ranch   STATE: Colorado   ELEV:  5955 ft
                         TEMPERATURE (°F), HEAT BASE 65.0, COOL BASE 65.0
                               DEP.   HEAT   COOL
             MEAN  MEAN        FROM   DEG    DEG                           MAX  MAX  MIN  MIN
    YEAR MO  MAX   MIN   MEAN  NORM   DAYS   DAYS  HIGH  DATE   LOW  DATE  >=90 <=32 <=32 <=0
    ------------------------------------------------------------------------------------------
    ` + this.state.yearlyTemperatureData + 
    `------------------------------------------------------------------------------------------
    ` + this.state.yearlyTemperatureSummaryData + `
    `

                        }</pre>
                    </div>
                    <div className='annualReportLongDiv'>
                        <div className='annualReportPrecipDiv' id='AnnualPrecipitationReport'>
                            <pre>{`
                  PRECIPITATION (in)
                    DEP.   MAX       DAYS OF RAIN
                    FROM   OBS.          OVER
    YEAR MO  TOTAL  NORM   DAY  DATE .01   .1   1
    ----------------------------------------------
    ` + this.state.yearlyPrecipitationData +
    `----------------------------------------------
    ` + this.state.yearlyPrecipitationSummaryData + `
`}
                            </pre>
                        </div>
                        <div className='annualReportWindDiv' id='AnnualWindReport'>
                            <pre>{`
          WIND SPEED (mph)

                                DOM
    YEAR MO   AVG.  HIGH  DATE  DIR
    --------------------------------
    ` + this.state.yearlyWindData +
    `--------------------------------
    ` + this.state.yearlyWindSummaryData + `
`}
                            </pre>
                        </div>
                    </div>
                    <div className='annualReportLongDiv' id='AnnualHumidityDewPointBarometerReport'>
                        <pre>{`
         HUMIDITY (%) DEW POINT (°F) BAROMETER (in.)
             AVG.  AVG.     AVG.   HIGH           LOW
    YEAR MO  HUM   DEW      BAR    BAR   DATE     BAR  DATE
    --------------------------------------------------------
    ` + this.state.yearlyHumidityData +
    `--------------------------------------------------------
    ` + this.state.yearlyHumiditySummaryData + `                    
`}</pre>
                    </div>
                    <div className='annualReportLongDiv' id='AnnualSolarReport'>
                        <pre>{`
                SOLAR RADIATION (W/M^2) UV (DOSE - MEDS)
             AVG.       TOTAL                 AVG.    TOTAL
             SOLAR      SOLAR    HIGH         UV      UV     HIGH
    YEAR MO  ENERGY     ENERGY   RAD   DATE   DOSE    DOSE   UV    DATE
    --------------------------------------------------------------------
    ` + this.state.yearlySolarData +
    `--------------------------------------------------------------------
    ` + this.state.yearlySolarSummaryData + `
`}</pre>
                    </div>
                </div>
            </div>
        );
    }
}

export { AnnualClimateReport };