import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import Highcharts from 'highcharts';
import {
  HighchartsChart, Chart, Tooltip, XAxis, YAxis, HighchartsProvider, ColumnSeries, LineSeries
} from 'react-jsx-highcharts';
import './home.scss';
import ReactGA from 'react-ga';
import { COLUMN, LINE } from '../Utils/constants';
import { formatOnePlace, formatThreePlaces, formatTwoPlaces } from '../Utils/utilities';
import { WeatherSeries } from '../models/weather-series';
import { GraphAxis } from '../models/graph-axis';
import { GraphOptions } from '../models/graph-options';

const HomePage = () => {
  const [graphOptions, setGraphOptions] = useState<GraphOptions[]>([]);
  const [loading, setLoading] = useState(true);
  const [weatherData, setWeatherData] = useState<any>({});
  const [allowGraphLoad, setAllowGraphLoad] = useState(false);
  const [weatherGraphSeries, setWeatherGraphSeries] = useState<WeatherSeries[]>([]);
  const [yAxises, setYAxises] = useState<GraphAxis[]>([]);

  useEffect(() => {
    ReactGA.pageview('Home');

    const getCurrentData = () => {
      const currentWeatherUrl = `${process.env.REACT_APP_HOME_CONTROLLER_ROOT}GetWeatherData`;

      axios.get(currentWeatherUrl)
        .then(response => {
          const momentDate = moment(response.data.recordDate);
          response.data.recordDate = momentDate.format('MMMM Do YYYY, h:mm a');
          setAllowGraphLoad(true);
          setLoading(false);
          setWeatherData(response.data);
        });
    }

    const loadGraphOptions = () => {
      const options = [];
      options.push({graphTitle: 'Outside Temperature', seriesTitle: 'Outside Temperature', color: 'var(--temperature-color)', id: 'temperature', label: '°', minValue: null, checked: true});
      options.push({graphTitle: 'THSW Index', seriesTitle: 'THSW Index', color: 'var(--thsw-index-color)', id: 'thswIndex', label: '°', minValue: null, checked: false});
      options.push({graphTitle: 'Heat Index', seriesTitle: 'Heat Index', color: 'var(--heat-index-color)', id: 'heatIndex', label: '°', minValue: null, checked: false});
      options.push({graphTitle: 'Wind Chill', seriesTitle: 'Wind Chill', color: 'var(--wind-chill-color)', id: 'windChill', label: '°', minValue: null, checked: false});
      options.push({graphTitle: 'Humidity', seriesTitle: 'Humidity', color: 'var(--humidity-color)', id: 'humidity', label: '%', minValue: 0, checked: false});
      options.push({graphTitle: 'Dewpoint', seriesTitle: 'Dewpoint', color: 'var(--dew-point-color)', id: 'dewpoint', label: '°', minValue: null, checked: false});
      options.push({graphTitle: 'UV Index', seriesTitle: 'UV Index', color: 'var(--uv-index-color)', id: 'uvIndex', label: '', minValue: 0, checked: false});
      options.push({graphTitle: 'Barometer', seriesTitle: 'Barometer', color: 'var(--barometer-color)', id: 'barometer', label: ' In.', minValue: null, checked: false});
      options.push({graphTitle: 'Solar Radiation', seriesTitle: 'Solar Radiation', color: 'var(--solar-radiation-color)', id: 'solarRadiation', label: ' W/M2', minValue: 0, checked: false});
      options.push({graphTitle: 'High Wind Speed', seriesTitle: 'High Wind Speed', color: 'var(--high-wind-speed-color)', id: 'highWindSpeed', label: ' MPH', minValue: 0, checked: false});
      options.push({graphTitle: 'Average Wind Speed', seriesTitle: 'Average Wind Speed', color: 'var(--average-wind-speed-color)', id: 'averageWindSpeed', label: ' MPH', minValue: 0, checked: false});
      options.push({graphTitle: 'Rainfall', seriesTitle: 'Rainfall', color: 'var(--rainfall-color)', id: 'rainfall', label: ' In.', minValue: 0, checked: false});

      setGraphOptions(options);
    };

    loadGraphOptions();
    getCurrentData();
  }, []);

  useEffect(() => {
    const removeGraphData = () => {
      const unSelectedWeatherTypes = graphOptions.filter((rec) => !rec.checked);
      const graphSeries = [...weatherGraphSeries];
      const yAxisesCopy = [...yAxises];
      let updated = false;
      let seriesUpdated = false;

      unSelectedWeatherTypes.forEach((type) => {
        const index = graphSeries.findIndex((series) => series.id === type.id);
        if (index >= 0) {
          const seriesYAxis = graphSeries.filter((ya) => ya.id === graphSeries[index].yAxis);

          if (seriesYAxis.length === 1) {
            const seriesYAxisIndex = yAxisesCopy.findIndex((ya) => ya.id === graphSeries[index].yAxis);
            yAxisesCopy.splice(seriesYAxisIndex, 1);
            seriesUpdated = true;
          }
          graphSeries.splice(index, 1);
          updated = true;
        }
      });

      if (updated) {
        setWeatherGraphSeries(graphSeries);
      }

      if (seriesUpdated) {
        setYAxises(yAxisesCopy);
      }
    }

    const addGraphData = () => {
      const selectedWeatherTypes = graphOptions.filter((rec) => {
        return rec.checked;
      });

      for (let i = 0; i < selectedWeatherTypes.length; i++) {
        const weatherType = selectedWeatherTypes[i].id;
        const yAxis = selectedWeatherTypes[i].label;

        if (weatherGraphSeries.filter((series) => series.id === weatherType).length === 0) {
          const weatherRec = graphOptions.find((elm) => { return elm.id === weatherType; });

          const weatherMethodUrl = `Get${weatherType}Data`;
          const weatherDataUrl = `${process.env.REACT_APP_HOME_CONTROLLER_ROOT}${weatherMethodUrl}`;

          // eslint-disable-next-line no-loop-func
          axios.get(weatherDataUrl).then((response) => {
            const graphData: number[][] = [];

            response.data.values.forEach((rec: any) => {
              const time = moment(rec.time).valueOf();
              graphData.push([time, rec.value]);
            });

            if (yAxises.filter((ya) => ya.id === yAxis).length === 0) {
              const chartYAxises = [...yAxises];

              const newAxis: GraphAxis = {
                id: yAxis,
                opposite: chartYAxises.length % 2 > 0,
                label: weatherRec?.label ? weatherRec.label : ''
              }

              if (selectedWeatherTypes[i].minValue) {
                newAxis.min = selectedWeatherTypes[i].minValue ?? undefined;
              }

              if (weatherType === 'humidity') {
                newAxis.max = 100;
              }

              if (weatherType === 'rainfall') {
                newAxis.tickInterval = 0.01;
              }

              setYAxises([...yAxises, newAxis]);
            }

            const newSeries: WeatherSeries = {
              name: weatherRec?.seriesTitle ? weatherRec.seriesTitle : '',
              color: weatherRec?.color ? weatherRec.color : '#FFFFFF',
              data: graphData,
              yAxis: yAxis,
              id: weatherType
            };

            if (weatherType === 'rainfall') {
              newSeries.type = COLUMN;
            } else {
              newSeries.type = LINE;
            }

            setWeatherGraphSeries([...weatherGraphSeries, newSeries]);
          });
        }
      }
    }

    if (allowGraphLoad) {
      addGraphData();
      removeGraphData();
    }
  }, [allowGraphLoad, graphOptions, weatherGraphSeries, yAxises]);

  const updateGraph = (id: string) => {
    const options = [...graphOptions];
    const option = options.find((elm) => { return elm.id === id; });

    if (option) {
      if (option?.checked) {
        option.checked = false;
      } else {
        option.checked = true;
      }

      setGraphOptions([...options]);
    }
  }



  const getChangeTempClass = () => {
    if (weatherData && weatherData.temperature24HourChange) {
      if (weatherData.temperature24HourChange > 0) {
        return 'redText';
      } else if (weatherData.temperature24HourChange < 0) {
        return 'blueText';
      }
    }

    return '';
  }

  return (
    <div className='dataContainer'>
      <div className='centeringDiv'>
        {loading &&
          <div className='overlay'>
            <div className='spinnerContainer'>
              <span className='spinner fa fa-spinner fa-spin'></span>
              <span className='loadingText'>Loading...</span>
            </div>
          </div>
        }
        <div className='mainSummaryBox'>
          <div className='lastUpdateDiv'>
            Last Update: {weatherData.recordDate} Mountain Time
          </div>

          {/************************ Temperature **********************************************************/}
          <div className='temperatureSummaryDiv'>
            <div className='weatherSummaryDiv'>
              <div className='summaryLabelDiv'>
                Temperature
              </div>
              <div className='currentTemperatureDiv'>{weatherData.temperature}&deg;</div>
              <div className='weatherDataDiv'>
                <div className='weatherValueDiv'>{weatherData.thswIndex}&deg;</div>
                THSW Index:
              </div>
              <div className='weatherDataDiv'>
                <div className='weatherValueDiv'>{weatherData.heatIndex}&deg;</div>
                Heat Index:
              </div>
              <div className='weatherDataDiv'>
                <div className='weatherValueDiv'>{weatherData.windChill}&deg;</div>
                Wind Chill:
              </div>
              <div className='weatherDataDiv'>
                <div className={'weatherValueDiv ' + getChangeTempClass()}>{formatOnePlace(weatherData.temperature24HourChange)}&deg;</div>
                24 Hour Chg:
              </div>
            </div>

            <div className='spaceDivSmall'></div>
            <div className='highLowValuesSpacer'></div>
            <div className='highLowValuesColumn boldText'>
              Today
            </div>
            <div className='highLowValuesColumn boldText'>
              Yesterday
            </div>
            <div className='spaceDivSmall'></div>
            <div className='highLowVColumn'>
              High:
            </div>
            <div className='highLowValuesColumn'>
              {weatherData.highTemperatureToday}&deg;
            </div>
            <div className='highLowValuesColumn'>
              {weatherData.highTemperatureYesterday}&deg;
            </div>
            <div className='highLowValuesColumn'>
              {moment(weatherData.highTemperatureTimeToday).format('h:mm a')}
            </div>
            <div className='highLowValuesColumn'>
              {moment(weatherData.highTemperatureTimeYesterday).format('h:mm a')}
            </div>

            <div className='spaceDivSmall'></div>
            <div className='highLowVColumn'>
              Low:
            </div>
            <div className='highLowValuesColumn'>
              {weatherData.lowTemperatureToday}&deg;
            </div>
            <div className='highLowValuesColumn'>
              {weatherData.lowTemperatureYesterday}&deg;
            </div>
            <div className='highLowValuesColumn'>
              {moment(weatherData.lowTemperatureTimeToday).format('h:mm a')}
            </div>
            <div className='highLowValuesColumn'>
              {moment(weatherData.lowTemperatureTimeYesterday).format('h:mm a')}
            </div>
          </div>

          {/************************ Wind **********************************************************/}
          <div className='weatherSummaryDiv'>
            <div className='summaryLabelDiv'>
              Wind
            </div>
            <div className='weatherDataTopDiv'>
              <div className='weatherValueDiv'>{weatherData.windDirection}</div>
              Direction:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.windSpeed} mph</div>
              Average Speed:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.windGust} mph</div>
              Gust Speed:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.highWindGust} mph at {moment(weatherData.highWindGustTime).format('h:mm a')}</div>
              Today:
            </div>
            <div className='weatherDataDiv'>
              &nbsp;
            </div>
          </div>

          {/****************************** Rain *************************************/}
          <div className='weatherSummaryDiv'>
            <div className='summaryLabelDiv'>
              Precipitation
            </div>
            <div className='weatherDataTopDiv'>
              <div className='weatherValueDiv'>{formatTwoPlaces(weatherData.rainToday)} in.</div>
              Today's Rain:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatTwoPlaces(weatherData.highRainRate)} in.</div>
              High Rain Rate (/hr):
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatTwoPlaces(weatherData.rainYesterday)} in.</div>
              Yesterday's Rain:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatTwoPlaces(weatherData.snowfallToday)} in.</div>
              Today's Snowfall*:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatTwoPlaces(weatherData.snowfallYesterday)} in.</div>
              Yesterday's Snowfall:
            </div>
          </div>

          {/************** Humidity *********************************/}
          <div className='weatherSummaryDiv'>
            <div className='summaryLabelDiv'>
              Humidity / Barometer
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.humidity}%</div>
              Humidity:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.dewpoint}&deg;</div>
              Dewpoint:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatThreePlaces(weatherData.barometer)} in.</div>
              Barometer:
            </div>
          </div>

          {/************** Solar *********************************/}
          <div className='weatherSummaryDiv'>
            <div className='summaryLabelDiv'>
              Solar
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.uvIndex}</div>
              UV Index:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.solarRadiation} W/m<sup>2</sup></div>
              Solar Radiation:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatTwoPlaces(weatherData.solarEnergy)} Langleys</div>
              Solar Energy:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatThreePlaces(weatherData.evapoTransparation)} in.</div>
              Evapo-Trans:
            </div>
          </div>

        </div>
        <div>
          <div className='graphTypeSelectionContainer'>
            {graphOptions.map((option) => (
              <div className='checkboxContainer' key={option.id}>
                <input type='checkbox' checked={option.checked} onChange={() => { updateGraph(option.id); }} />
                <span className={option.id}>{option.seriesTitle}</span>
              </div>))
            }
          </div>
          <div className='graphContainer'>
            <HighchartsProvider Highcharts={Highcharts}>
              <HighchartsChart time={{ timezone: 'America/Denver' }}>
                <Chart height={285} width={652} />
                <Tooltip shared={true} />
                <XAxis
                  type="datetime"
                  labels={{
                    style: { color: '#707073' }
                  }}
                />
                {yAxises.map((yAxis) => (
                  <YAxis id={yAxis.id}
                    key={yAxis.id}
                    opposite={yAxis.opposite}
                    min={yAxis.min}
                    max={yAxis.max}
                    tickInterval={yAxis.tickInterval}
                    labels={{
                      format: '{text}' + yAxis.label,
                      style: { color: '#707073' }
                    }}>
                    {weatherGraphSeries.filter((series) => series.yAxis === yAxis.id).map((graph) => {
                      if (graph.type === LINE) {
                        return <LineSeries
                          key={graph.name}
                          data={graph.data}
                          name={graph.name}
                          color={graph.color}
                          yAxis={graph.yAxis} />;
                      } else {
                        return <ColumnSeries
                          key={graph.name}
                          data={graph.data}
                          name={graph.name}
                          color={graph.color}
                          yAxis={graph.yAxis} />;
                      }
                    })}
                  </YAxis>
                ))}
              </HighchartsChart>
            </HighchartsProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
