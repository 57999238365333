import React, { ReactElement, useEffect, useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import Axios from 'axios';
import moment from 'moment';
import 'rc-slider/assets/index.css';
import './lightning.scss';
import { LightningStrike } from '../models/lightning-strike';
import Slider from 'rc-slider';
import { LightningStrikeApi } from '../models/lightning-strike-api';

const Lightning = (props: any) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAwLARifzAKBdUT5VGGBLrCN5nzuFA3UhY'
  });

  const darkMap = [
    {
      stylers: [{ 'invert_lightness': true }
        , { 'gamma': 0.8 }
        , { 'lightness': -19 }]
    }
  ];

  let timeoutCode: any;

  //const [map, setMap] = useState<google.maps.Map | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [cgPos, setCgPos] = useState<number>(0);
  const [cgNeg, setCgNeg] = useState<number>(0);
  const [icPos, setIcPos] = useState<number>(0);
  const [icNeg, setIcNeg] = useState<number>(0);
  const [totalStrikes, setTotalStrikes] = useState<number>(0);
  const [lastStrike, setLastStrike] = useState<string>('');
  const [highHoursBack, setHighHoursBack] = useState<number>(0);
  const [lowHoursBack, setLowHoursBack] = useState<number>(1);
  const [cgPosCheck, setCgPosCheck] = useState<boolean>(true);
  const [cgNegCheck, setCgNegCheck] = useState<boolean>(true);
  const [icPosCheck, setIcPosCheck] = useState<boolean>(true);
  const [icNegCheck, setIcNegCheck] = useState<boolean>(true);
  const [cgPosRecFillWidth, setCgPosRecFillWidth] = useState<number>(0);
  const [cgNegRecFillWidth, setCgNegRecFillWidth] = useState<number>(0);
  const [icPosRecFillWidth, setIcPosRecFillWidth] = useState<number>(0);
  const [icNegRecFillWidth, setIcNegRecFillWidth] = useState<number>(0);
  const [lightningStrikes, setLightningStrikes] = useState<LightningStrike[]>([]);
  const [strikeMarkers, setStrikeMarkers] = useState<ReactElement[]>([]);
  
  const mapCenter = { lat: 39.52385, lng: -104.928233 };
  const sliderMarks = {
    '-12': {
      style: {
        color: '#333',
      },
      label: -12
    },
    '-11': ' ',
    '-10': ' ',
    '-9': {
      style: {
        color: '#333',
      },
      label: -9
    },
    '-8': ' ',
    '-7': ' ',
    '-6': {
      style: {
        color: '#333',
      },
      label: -6
    },
    '-5': ' ',
    '-4': ' ',
    '-3': {
      style: {
        color: '#333',
      },
      label: -3
    },
    '-2': ' ',
    '-1': ' ',
    '0': {
      style: {
        color: '#333',
      },
      label: 0
    }
  };

  useEffect(() => {
    const strikes: LightningStrike[] = [];
    let cgPositive = 0;
    let cgNegitive = 0;
    let icPositive = 0;
    let icNegitive = 0;
    let totalLightningStrikes = 0;
    let lastLightningStrike = 'N/A';
    let cgPosRecFillWidth = 0;
    let cgNegRecFillWidth = 0;
    let icPosRecFillWidth = 0;
    let icNegRecFillWidth = 0;

    const endDate = moment.utc().subtract(highHoursBack, 'h').format('YYYY-MM-DD HH:mm:ss');
    const startDate = moment.utc().subtract(lowHoursBack, 'h').format('YYYY-MM-DD HH:mm:ss');

    const dates = { startDate: moment.utc(startDate), endDate: moment.utc(endDate) };

    lightningStrikes.forEach((lightningStrike) => {
      const strikeDate = moment.utc(lightningStrike.dateTime);

      if (strikeDate >= dates.startDate && strikeDate <= dates.endDate) {
        if (lightningStrike.strikeType === '+CG' && cgPosCheck) {
          totalLightningStrikes++;
          cgPositive++;
          strikes.push(lightningStrike);
        } else if (lightningStrike.strikeType === '-CG' && cgNegCheck) {
          totalLightningStrikes++;
          cgNegitive++;
          strikes.push(lightningStrike);
        } else if (lightningStrike.strikeType === '+IC' && icPosCheck) {
          totalLightningStrikes++;
          icPositive++;
          strikes.push(lightningStrike);
        } else if (lightningStrike.strikeType === '-IC' && icNegCheck) {
          totalLightningStrikes++;
          icNegitive++;
          strikes.push(lightningStrike);
        }
      }
    });

    if (totalLightningStrikes > 0) {
      cgPosRecFillWidth = Math.floor(cgPositive / totalLightningStrikes * 71);
      cgNegRecFillWidth = Math.floor(cgNegitive / totalLightningStrikes * 71);
      icPosRecFillWidth = Math.floor(icPositive / totalLightningStrikes * 71);
      icNegRecFillWidth = Math.floor(icNegitive / totalLightningStrikes * 71);
    }

    if (strikes.length > 0) {
      lastLightningStrike = moment(strikes[0].dateTime).format('MM/DD/YYYY h:mm a');
    }

    setCgPos(cgPositive);
    setCgNeg(cgNegitive);
    setIcPos(icPositive);
    setIcNeg(icNegitive);
    setCgPosRecFillWidth(cgPosRecFillWidth);
    setCgNegRecFillWidth(cgNegRecFillWidth);
    setIcNegRecFillWidth(icNegRecFillWidth);
    setIcPosRecFillWidth(icPosRecFillWidth);
    setTotalStrikes(totalLightningStrikes);
    setLastStrike(lastLightningStrike);

    const markers: ReactElement[] = [];

    strikes.forEach((strike) => {
      markers.push(
        <Marker
          position={{ lat: strike.coords.latitude, lng: strike.coords.longitude }}
          key={strike.id}
          icon={strike.icon}
        >
        </Marker>
      );
    });

    setStrikeMarkers([...markers]);

  }, [icPosCheck, icNegCheck, cgPosCheck, cgNegCheck, lightningStrikes, highHoursBack, lowHoursBack]);

  const onLoad = (mapParam: google.maps.Map) => {
    // setMap(mapParam);
    getLightningStrikes();
  };

  const onUnmount = (mapParam: google.maps.Map) => {
    // setMap(null)
  };

  const getLightningStrikes = () => {
    setLightningStrikes([]);
    setStrikeMarkers([]);

    const endDate = encodeURIComponent(moment.utc().subtract(highHoursBack, 'h').format('MM-DD-YYYY HH:mm:ss'));
    const startDate = encodeURIComponent(moment.utc().subtract(lowHoursBack, 'h').format('MM-DD-YYYY HH:mm:ss'));

    Axios.get<LightningStrikeApi[]>(process.env.REACT_APP_LIGHTNING_CONTROLLER_ROOT + 'GetLightningStrikes?startDate=' + startDate + '&endDate=' + endDate)
      .then(response => {
        const data: LightningStrikeApi[] = response.data;
        const strikes: LightningStrike[] = [];

        data.forEach((strike) => {
          let icon = '';
          if (moment.utc(strike.strikeDate) > moment.utc().subtract(5, 'm')) {
            if (strike.strikeType === '+IC') {
              icon = 'icPos.png';
            }
            else if (strike.strikeType === '-IC') {
              icon = 'icNeg.png';
            }
            else if (strike.strikeType === '+CG') {
              icon = 'cgPos.png';
            }
            else {
              icon = 'cgNeg.png';
            }
          }
          else if (moment.utc(strike.strikeDate) > moment.utc().subtract(10, 'm')) {
            icon = 'strike5Mins.png';
          }
          else if (moment.utc(strike.strikeDate) > moment.utc().subtract(20, 'm')) {
            icon = 'strike10Mins.png';
          }
          else if (moment.utc(strike.strikeDate) > moment.utc().subtract(30, 'm')) {
            icon = 'strike20Mins.png';
          }
          else {
            icon = 'strike30Mins.png';
          }

            strikes.push({
            id: strike.id,
            coords: {
              latitude: strike.latitude,
              longitude: strike.longitude
            },
            dateTime: moment(strike.strikeDate),
            icon: process.env.REACT_APP_IMAGE_DIR + icon,
            strikeType: strike.strikeType
          });
        });

        setLightningStrikes([...strikes]);
        setLoading(false);
      },
        () => {
          setLoading(false);
        });
  };

  const strikeCheckboxChanged = (event: any) => {
    const checked = event.target.checked;
    const val = event.target.value;

    if (val === 'icPos') {
      setIcPosCheck(checked);
    }
    else if (val === 'icNeg') {
      setIcNegCheck(checked);
    }
    else if (val === 'cgPos') {
      setCgPosCheck(checked);
    }
    else if (val === 'cgNeg') {
      setCgNegCheck(checked);
    }
  };

  const sliderChanged = (value: number | number[]) => {
    if (Array.isArray(value)) {
      setLowHoursBack(value[0] * -1);
      setHighHoursBack(value[1] * -1);
      
      clearTimeout(timeoutCode);
      timeoutCode = setTimeout(function () {
        getLightningStrikes();
      }, 500);
    }
  }

  return isLoaded ? (
    <div className="lightning">
      {loading &&
        <div className="overlay">
          <div className="spinnerContainer">
            <span className="spinner fa fa-spinner fa-spin"></span>
            <span className="loadingText">Loading...</span>
          </div>
        </div>
      }
      <div>
        <GoogleMap
          mapContainerClassName="mapContainer"
          center={mapCenter}
          zoom={8}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{styles: darkMap}}
        >
          {strikeMarkers}
        </GoogleMap>
      </div>

      <div className="controlBox">
        <div className="row">
          <div className="strikeTypeField">
            <input type="checkbox" checked={cgPosCheck} value="cgPos" onChange={strikeCheckboxChanged} /> CG+
            <img src="/images/cgPos.png" alt="cgPos" title="Cloud to Ground Positive" />
          </div>
          <div className="strikeTypeField">
            <input type="checkbox" checked={cgNegCheck} value="cgNeg" onChange={strikeCheckboxChanged} /> CG-
            <img src="/images/cgNeg.png" alt="cgNeg" title="Cloud to Ground Negative" />
          </div>
          <div className="strikeTypeField">
            <input type="checkbox" checked={icPosCheck} value="icPos" onChange={strikeCheckboxChanged} /> IC+
            <img src="/images/icPos.png" alt="icPos" title="Intra Cloud Positive" />
          </div>
          <div className="strikeTypeField">
            <input type="checkbox" checked={icNegCheck} value="icNeg" onChange={strikeCheckboxChanged} /> IC-
            <img src="/images/icNeg.png" alt="icNeg" title="Intra Cloud Negative" />
          </div>
        </div>
        <svg className="strikeRectContainer">
          <rect width="75" height="15" x="5" y="1" className="outline"></rect>
          <rect width={cgPosRecFillWidth} height="11" x="7" y="3" className="cgPos"></rect>

          <rect width="75" height="15" x="90" y="1" className="outline"></rect>
          <rect width={cgNegRecFillWidth} height="11" x="92" y="3" className="cgNeg"></rect>

          <rect width="75" height="15" x="175" y="1" className="outline"></rect>
          <rect width={icPosRecFillWidth} height="11" x="177" y="3" className="icPos"></rect>

          <rect width="75" height="15" x="260" y="1" className="outline"></rect>
          <rect width={icNegRecFillWidth} height="11" x="262" y="3" className="icNeg"></rect>
        </svg>
        <div className="strikeCountRow">
          <div className="strikeCountField">{cgPos}</div>
          <div className="strikeCountField">{cgNeg}</div>
          <div className="strikeCountField">{icPos}</div>
          <div className="strikeCountField">{icNeg}</div>
        </div>

        <div className="row">
          <div className="totalStikeField">Total Strikes: {totalStrikes}</div>
          <div className="lastStikeField">Last Strike Time: {lastStrike}</div>
        </div>
        <div className="row">
          <div className="olderStrikeContainer">
            <div className="row">
              <div>Older Strike Colors</div>
              <div className="olderStrikeField">
                <img src="/images/strike5Mins.png" alt="5 - 10 Minutes" title="5 - 10 Minutes" />
              </div>
              <div className="olderStrikeField">
                <img src="/images/strike10Mins.png" alt="10 -20 Minutes" title="10 - 20 Minutes" />
              </div>
              <div className="olderStrikeField">
                <img src="/images/strike20Mins.png" alt="20 - 30 Minutes" title="20 - 30 Minutes" />
              </div>
              <div className="olderStrikeField">
                <img src="/images/strike30Mins.png" alt="30+ Minutes" title="30+ Minutes" />
              </div>
            </div>

            <div className="row">
              <div className="olderStrikeLabel">
                5+
              </div>
              <div className="olderStrikeLabel">
                10+
              </div>
              <div className="olderStrikeLabel">
                20+
              </div>
              <div className="olderStrikeLabel">
                30+
              </div>
            </div>
          </div>

          <div className="sliderContainer">
            <Slider
              range
              min={-12}
              max={0}
              marks={sliderMarks}
              onChange={sliderChanged}
              value={[lowHoursBack * -1, highHoursBack * -1]}
            />
          </div>
        </div>
      </div>
    </div>
  ) : <></>
}

export default Lightning;