import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Highcharts from 'highcharts';
import * as moment from 'moment';
import './cwop-station.scss';
import ReactGA from 'react-ga';
import { formatOnePlace, formatThreePlaces, formatTwoPlaces } from '../Utils/utilities';
import { Chart, ColumnSeries, HighchartsChart, HighchartsProvider, LineSeries, Tooltip, XAxis, YAxis } from 'react-jsx-highcharts';
import { LINE } from '../Utils/constants';
import { GraphOptions } from '../models/graph-options';
import { WeatherSeries } from '../models/weather-series';
import { GraphAxis } from '../models/graph-axis';

const CwopStation = (props: any) => {
  const [graphOptions, setGraphOptions] = useState<GraphOptions[]>([]);
  const [loading, setLoading] = useState(true);
  const [weatherData, setWeatherData] = useState<any>({});
  const [allowGraphLoad, setAllowGraphLoad] = useState(false);
  const [weatherGraphSeries, setWeatherGraphSeries] = useState<WeatherSeries[]>([]);
  const [yAxises, setYAxises] = useState<GraphAxis[]>([]);

  useEffect(() => {
    ReactGA.pageview('CWOPStation');

    const getCurrentData = () => {
      const callSign = props.match.params.callSign ? props.match.params.callSign : 'cw2323';
      const currentWeatherUrl = `${process.env.REACT_APP_CWOP_CONTROLLER_ROOT}GetWeatherStationLastRecord?callSign=${callSign}`;

      axios.get(currentWeatherUrl)
        .then(response => {
          const momentDate = moment.utc(response.data.receiveDate);
          const momentUtcString = momentDate.format('MMMM Do YYYY, h:mm a');

          response.data.recordDate = moment.utc(response.data.receiveDate)
            .add(response.data.hoursOffset, 'hours')
            .format('MMMM Do YYYY, h:mm a') + ` (${momentUtcString} UTC)`;
          setLoading(false);
          setWeatherData(response.data);
          setAllowGraphLoad(true);
        });
    }

    const loadGraphOptions = () => {
      const options: GraphOptions[] = [];
      options.push({ graphTitle: 'Outside Temperature', seriesTitle: 'Outside Temperature', color: 'var(--temperature-color)', id: 'temperature', label: '°', minValue: null, checked: true });
      options.push({ graphTitle: 'Humidity', seriesTitle: 'Humidity', color: 'var(--humidity-color)', id: 'humidity', label: '%', minValue: 0, checked: false });
      options.push({ graphTitle: 'Barometer', seriesTitle: 'Barometer', color: 'var(--barometer-color)', id: 'barometer', label: ' In.', minValue: null, checked: false });
      options.push({ graphTitle: 'Solar Radiation', seriesTitle: 'Solar Radiation', color: 'var(--solar-radiation-color)', id: 'solar', label: ' W/M2', minValue: 0, checked: false });
      options.push({ graphTitle: 'High Wind Speed', seriesTitle: 'High Wind Speed', color: 'var(--high-wind-speed-color)', id: 'highWind', label: ' MPH', minValue: 0, checked: false });
      options.push({ graphTitle: 'Average Wind Speed', seriesTitle: 'Average Wind Speed', color: 'var(--average-wind-speed-color)', id: 'avgWind', label: ' MPH', minValue: 0, checked: false });

      setGraphOptions(options);
    }

    loadGraphOptions();
    getCurrentData();
  }, [props.match.params.callSign]);

  useEffect(() => {
    const removeGraphData = () => {
      const unSelectedWeatherTypes = graphOptions.filter((rec) => !rec.checked);
      const graphSeries = [...weatherGraphSeries];
      const yAxisesCopy = [...yAxises];
      let updated = false;
      let seriesUpdated = false;

      unSelectedWeatherTypes.forEach((type) => {
        const index = graphSeries.findIndex((series) => series.id === type.id);
        if (index >= 0) {
          const seriesYAxis = graphSeries.filter((ya) => ya.id === graphSeries[index].yAxis);

          if (seriesYAxis.length === 1) {
            const seriesYAxisIndex = yAxisesCopy.findIndex((ya) => ya.id === graphSeries[index].yAxis);
            yAxisesCopy.splice(seriesYAxisIndex, 1);
            seriesUpdated = true;
          }
          graphSeries.splice(index, 1);
          updated = true;
        }
      });

      if (updated) {
        setWeatherGraphSeries(graphSeries);
      }

      if (seriesUpdated) {
        setYAxises(yAxisesCopy);
      }
    }

    const addGraphData = () => {
      const selectedWeatherTypes = graphOptions.filter((rec) => {
        return rec.checked;
      });

      selectedWeatherTypes.forEach((selectedWeatherType) => {
        const weatherType = selectedWeatherType.id;
        const yAxis = selectedWeatherType.label;

        if (weatherGraphSeries.filter((series) => series.id === weatherType).length === 0) {
          const weatherRec = graphOptions.find((elm) => { return elm.id === weatherType; });
          let weatherMethodUrl = `Get${weatherType}Data`;
          const weatherDataUrl = `${process.env.REACT_APP_CWOP_CONTROLLER_ROOT}${weatherMethodUrl}?callSign=${props.match.params.callSign}&hoursBack=24`;

          axios.get(weatherDataUrl)
            .then(response => {
              const graphData: any[] = [];

              response.data.values.forEach((rec: any) => {
                const time = moment.utc(rec.time).add(weatherData.hoursOffset, 'hours').valueOf();

                if (weatherType === 'barometer') {
                  rec.value = convertMbToIn(rec.value) / 10;
                }

                graphData.push([time, rec.value]);
              });

              if (yAxises.filter((ya) => ya.id === yAxis).length === 0) {
                const chartYAxises = [...yAxises];

                const newAxis: GraphAxis = {
                  id: yAxis,
                  opposite: chartYAxises.length % 2 > 0,
                  label: weatherRec ? weatherRec?.label : ''
                }

                if (selectedWeatherType.minValue) {
                  newAxis.min = selectedWeatherType.minValue;
                }

                if (weatherType === 'humidity') {
                  newAxis.max = 100;
                }

                setYAxises([...yAxises, newAxis]);
              }

              const newSeries = {
                name: weatherRec ? weatherRec.seriesTitle : '',
                color: weatherRec ? weatherRec.color : '',
                data: graphData,
                yAxis: yAxis,
                id: weatherType,
                type: LINE
              };

              setWeatherGraphSeries([...weatherGraphSeries, newSeries]);
            });
        }
      });
    }

    if (allowGraphLoad) {
      addGraphData();
      removeGraphData();
    }
  }, [allowGraphLoad, graphOptions, props.match.params.callSign, weatherData.hoursOffset, weatherGraphSeries, yAxises]);

  const updateGraph = (id: any) => {
    const options = [...graphOptions];
    const option = options.find((elm) => { return elm.id === id; });

    if (option?.checked) {
      option.checked = false;
    } else {
      ReactGA.event({
        category: 'Cwop',
        action: 'Graph',
        label: option?.graphTitle
      });

      if (option) {
        option.checked = true;
      }
    }

    setGraphOptions([...options]);
  }

  const calculateWindDirection = (degrees: number) => {
    //N
    if (degrees >= 350 || degrees <= 11) {
      return 'N';
    }
    //NNE
    else if (degrees >= 12 && degrees <= 34) {
      return 'NNE';
    }
    //NE
    else if (degrees >= 35 && degrees <= 56) {
      return 'NE';
    }
    //ENE
    else if (degrees >= 57 && degrees <= 79) {
      return 'ENE';
    }
    //E
    else if (degrees >= 80 && degrees <= 101) {
      return 'E';
    }
    //ESE
    else if (degrees >= 102 && degrees <= 124) {
      return 'ESE';
    }
    //SE
    else if (degrees >= 125 && degrees <= 146) {
      return 'SE';
    }
    //SSE
    else if (degrees >= 147 && degrees <= 169) {
      return 'SSE';
    }
    //S
    else if (degrees >= 170 && degrees <= 191) {
      return 'S';
    }
    //SSW
    else if (degrees >= 192 && degrees <= 214) {
      return 'SSW';
    }
    //SW
    else if (degrees >= 215 && degrees <= 236) {
      return 'SW';
    }
    //WSW
    else if (degrees >= 237 && degrees <= 259) {
      return 'WSW';
    }
    //W
    else if (degrees >= 260 && degrees <= 281) {
      return 'W';
    }
    //WNW
    else if (degrees >= 282 && degrees <= 304) {
      return 'WNW';
    }
    //NW
    else if (degrees >= 305 && degrees <= 326) {
      return 'NW';
    }
    //NNW
    else if (degrees >= 327 && degrees <= 349) {
      return 'NNW';
    } else {
      return '---';
    }
  }

  const convertMbToIn = (mb: number) => {
    return (mb * 0.0295301);
  }

  const calculateDewpoint = (humidity: number, temperature: number) => {
    if (humidity && temperature) {
      const tempC = (temperature - 32.0) * 5.0 / 9.0;
      const vaporPressure = humidity * 0.01 * 6.112 * Math.exp((17.62 * tempC) / (tempC + 243.12));
      const numerator = 243.12 * Math.log(vaporPressure) - 440.1;
      const denominator = 19.43 - (Math.log(vaporPressure));
      const dewPoint = numerator / denominator;

      return formatOnePlace(dewPoint * 1.8 + 32.0);
    }
  }

  return (
    <div className='dataContainer'>
      <div className='centeringDiv'>
        {loading &&
          <div className='overlay'>
            <div className='spinnerContainer'>
              <span className='spinner fa fa-spinner fa-spin'></span>
              <span className='loadingText'>Loading...</span>
            </div>
          </div>
        }
        <div className='mainSummaryBox'>
          <div className='lastUpdateDiv'>
            Last Update: {weatherData.recordDate}
          </div>

          {/************************ Temperature **********************************************************/}
          <div className='temperatureSummaryDiv'>
            <div className='weatherSummaryDiv'>
              <div className='summaryLabelDiv'>
                Temperature
              </div>
              <div className='currentTemperatureDiv'>{weatherData.temperature}&deg;</div>
              <div className='weatherDataDiv'>
                <div className='weatherValueDiv'>{weatherData.heatIndex}&deg;</div>
                Heat Index:
              </div>
              <div className='weatherDataDiv'>
                <div className='weatherValueDiv'>{weatherData.windChill}&deg;</div>
                Wind Chill:
              </div>
            </div>

            <div className='spaceDivSmall'></div>
            <div className='highLowValuesSpacer'></div>
            <div className='highLowValuesColumn boldText'>
              Today
            </div>
            <div className='highLowValuesColumn boldText'>
              Yesterday
            </div>
            <div className='spaceDivSmall'></div>
            <div className='highLowVColumn'>
              High:
            </div>
            <div className='highLowValuesColumn'>
              {weatherData.todayHighTemperature}&deg;
            </div>
            <div className='highLowValuesColumn'>
              {weatherData.yesterdayHighTemperature}&deg;
            </div>
            <div className='highLowValuesColumn'>
              {moment.utc(weatherData.todayHighTemperatureTime).add(weatherData.hoursOffset, 'hours').format('h:mm a')}
            </div>
            <div className='highLowValuesColumn'>
              {moment.utc(weatherData.yesterdayHighTemperatureTime).add(weatherData.hoursOffset, 'hours').format('h:mm a')}
            </div>

            <div className='spaceDivSmall'></div>
            <div className='highLowVColumn'>
              Low:
            </div>
            <div className='highLowValuesColumn'>
              {weatherData.todayLowTemperature}&deg;
            </div>
            <div className='highLowValuesColumn'>
              {weatherData.yesterdayLowTemperature}&deg;
            </div>
            <div className='highLowValuesColumn'>
              {moment.utc(weatherData.todayLowTemperatureTime).add(weatherData.hoursOffset, 'hours').format('h:mm a')}
            </div>
            <div className='highLowValuesColumn'>
              {moment.utc(weatherData.yesterdayLowTemperatureTime).add(weatherData.hoursOffset, 'hours').format('h:mm a')}
            </div>
          </div>

          {/************************ Wind **********************************************************/}
          <div className='weatherSummaryDiv'>
            <div className='summaryLabelDiv'>
              Wind
            </div>
            <div className='weatherDataTopDiv'>
              <div className='weatherValueDiv'>{calculateWindDirection(weatherData.windDirection) + ' (' + weatherData.windDirection + '°)'}</div>
              Direction:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.windSpeed} mph</div>
              Average Speed:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.windGust} mph</div>
              Gust Speed:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.todayWindGust} mph at {moment.utc(weatherData.todayWindGustTime).add(weatherData.hoursOffset, 'hours').format('h:mm a')}</div>
              Today:
            </div>
            <div className='weatherDataDiv'>
              &nbsp;
            </div>
          </div>

          {/****************************** Rain *************************************/}
          <div className='weatherSummaryDiv'>
            <div className='summaryLabelDiv'>
              Precipitation
            </div>
            <div className='weatherDataTopDiv'>
              <div className='weatherValueDiv'>{formatTwoPlaces(weatherData.rainToday)} in.</div>
              Today's Rain:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatTwoPlaces(weatherData.rainLastHour)} in.</div>
              Rain Last Hour:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatTwoPlaces(weatherData.rain24Hours)} in.</div>
              Rain Last 24 Hours:
            </div>
          </div>

          {/************** Humidity *********************************/}
          <div className='weatherSummaryDiv'>
            <div className='summaryLabelDiv'>
              Humidity / Barometer
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{weatherData.humidity}%</div>
              Humidity:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{calculateDewpoint(weatherData.humidity, weatherData.temperature)}&deg;</div>
              Dewpoint:
            </div>
            <div className='weatherDataDiv'>
              <div className='weatherValueDiv'>{formatThreePlaces(convertMbToIn(weatherData.barometer))} in.</div>
              Barometer:
            </div>
          </div>

          {/************** Solar *********************************/}
          <div className='weatherSummaryDiv'>
            <div className='summaryLabelDiv'>
              Solar
            </div>
            <div className='weatherDataDiv'>
              {weatherData.solarRadiation !== null ?
                (
                  <div className='weatherValueDiv'>{weatherData.solarRadiation} W/m<sup>2</sup></div>
                ) :
                (
                  <div className='weatherValueDiv'>No Solar Data</div>
                )
              }
              Solar Radiation:
            </div>
          </div>

        </div>
        <div>
          <div className="graphTypeSelectionContainer">
            {graphOptions.map((option) => (
              <div className="checkboxContainer" key={option.id}>
                <input type="checkbox" checked={option.checked} onChange={() => { updateGraph(option.id); }} />
                <span className={option.id}>{option.seriesTitle}</span>
              </div>))
            }
          </div>
          <div className="graphContainer">
            <HighchartsProvider Highcharts={Highcharts}>
              <HighchartsChart>
                <Chart height={280} width={652} />
                <Tooltip shared={true} />
                <XAxis
                  type="datetime"
                  labels={{
                    style: { color: '#707073' }
                  }}
                />
                {yAxises.map((yAxis) => (
                  <YAxis id={yAxis.id}
                    key={yAxis.id}
                    opposite={yAxis.opposite}
                    min={yAxis.min}
                    max={yAxis.max}
                    labels={{
                      format: '{text}' + yAxis.label,
                      style: { color: '#707073' }
                    }}>
                    {weatherGraphSeries.filter((series) => series.yAxis === yAxis.id).map((graph) => {
                      if (graph.type === LINE) {
                        return <LineSeries
                          key={graph.name}
                          data={graph.data}
                          name={graph.name}
                          color={graph.color}
                          yAxis={graph.yAxis} />;
                      } else {
                        return <ColumnSeries
                          key={graph.name}
                          data={graph.data}
                          name={graph.name}
                          color={graph.color}
                          yAxis={graph.yAxis} />;
                      }
                    })}
                  </YAxis>
                ))}
              </HighchartsChart>
            </HighchartsProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CwopStation;