import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import PropTypes from 'prop-types';

const Records = (props) => {
    return (
        <div style={ { width: 410 } }>
            <DataTable value={props.records}
                    className="weatherCalendarTable"
                    // expandedRows={expandedRows}
                    // onRowToggle={(e) => setExpandedRows(e.data)}
                    // onRowExpand={onRowExpand}
                    // onRowCollapse={onRowCollapse}
                    // rowExpansionTemplate={rowExpansionTemplate}
                >
                <Column expander style={{ width: '5px' }} />
                <Column field="weatherType" header={props.columnHeadings.col1} style={{ width: '200px' }} />
                <Column field="displayValue"
                    header={props.columnHeadings.col2}
                    headerStyle={{textAlign: 'center'}}
                    style={{ width: '85px', textAlign: 'right' }}
                />
                <Column field="misc"
                    header={props.columnHeadings.col3}
                    headerStyle={{textAlign: 'center'}}
                    style={{ width: '80px', textAlign: 'right' }}
                />
            </DataTable>
        </div>
    );
}

Records.propTypes = {
    records: PropTypes.array,
    columnHeadings: PropTypes.object
}

export default Records;
