import React, { useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import Axios from 'axios';
import 'rc-slider/assets/index.css';
import './radar.scss';
import { CoordinatesXY } from '../models/coordinates';

const Radar = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAwLARifzAKBdUT5VGGBLrCN5nzuFA3UhY'
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [allRadarTimes, setAllRadarTimes] = useState<string[]>([]);

  const calledOnce = React.useRef(false);
  const mapCenter = { lat: 39.52385, lng: -104.928233 };

  useEffect(() => {
    if (calledOnce.current) {
      return;
    }

    Axios.get('https://opengeo.ncep.noaa.gov/geoserver/conus/conus_bref_qcd/ows?service=wms&version=1.3.0&request=GetCapabilities')
      .then((reponse) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(reponse.data, 'text/xml');

        const dimensions = doc.getElementsByTagName('Dimension');

        for (const dimension of dimensions) {
          if ((dimension.attributes as any).name && (dimension.attributes as any).name.value === 'time') {
            const allTimes = dimension.innerHTML.split(',');
            setAllRadarTimes([...allTimes]);
            break;
          }
        }
      });

    calledOnce.current = true;
  });

  const onLoad = (mapParam: google.maps.Map) => {
    setMap(mapParam);
  };

  const onUnmount = (mapParam: google.maps.Map) => {
    setMap(null)
  };

  const getTileUrl = (coordinates: CoordinatesXY, zoom: number) => {
    if (allRadarTimes && allRadarTimes.length > 0) {
      const radarDate = allRadarTimes[allRadarTimes.length - 1];

      return 'https://opengeo.ncep.noaa.gov/geoserver/conus/conus_bref_qcd/ows' +
        '?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=true&TILES=true&LAYERS=conus_bref_qcd' +
        `&TIME=${radarDate}` +
        '&WIDTH=256&HEIGHT=256&SRS=EPSG:3857' +
        `&BBOX=${xyzToBounds(coordinates.x, coordinates.y, zoom).join(',')}`;
    }

    return '';
  };

  const xyzToBounds = (x: number, y: number, z: number) => {
    const entent = [-Math.PI * 6378137, Math.PI * 6378137];
    const tileSize = (entent[1] * 2) / Math.pow(2, z);
    const minX = entent[0] + x * tileSize;
    const maxX = entent[0] + (x + 1) * tileSize;
    // remember y origin starts at top
    const minY = entent[1] - (y + 1) * tileSize;
    const maxY = entent[1] - y * tileSize;

    return [minX, minY, maxX, maxY];
  }

  if (map && allRadarTimes && allRadarTimes.length > 0) {
    const landcover = new google.maps.ImageMapType({
      getTileUrl: getTileUrl,
      name: "Radar",
      alt: "Radar Image",
      minZoom: 0,
      maxZoom: 19,
      opacity: 0.6
    });

    map.overlayMapTypes.push(landcover);
  }

  // componentWillMount() {

  //     this.imageArray = [];

  //     axios.get(process.env.REACT_APP_RADAR_CONTROLLER_ROOT + 'GetRadarImages')
  //         .then (response => {
  //             this.imageArrayLoaded = true;

  //             if (response.data)
  //             {
  //                 if (response.data.length > 10)
  //                 {
  //                     const deleteNum = response.data.length - 10;
  //                     response.data.splice(0, deleteNum);
  //                 }

  //                 this.imageArray = response.data;
  //                 let groundOverlays = this.imageArray.map((img) =>
  //                 {
  //                     return {path: img, inUse: false};
  //                 });

  //                 groundOverlays[groundOverlays.length - 1].inUse = true;
  //                 this.setState({groundOverlays: groundOverlays, currentImageIndex: groundOverlays.length - 1});
  //             }

  //             this.setState({loading: false});
  //         },
  //         () => {
  //             this.setState({loading: false});
  //         });
  // }

  // const goToFirstImage = () => {

  //   let newOverlays = this.state.groundOverlays;
  //   for (let i = 0; i < newOverlays.length; i++) {
  //     newOverlays[i].inUse = false;
  //   }

  //   newOverlays[0].inUse = true;

  //   this.setState({ groundOverlays: null });
  //   setTimeout(() => this.setState({ groundOverlays: newOverlays, currentImageIndex: 0 }), 1);
  // }

  // const goToLastImage = () => {
  //   let newOverlays = this.state.groundOverlays;
  //   for (let i = 0; i < newOverlays.length; i++) {
  //     newOverlays[i].inUse = false;
  //   }

  //   newOverlays[newOverlays.length - 1].inUse = true;

  //   this.setState({ groundOverlays: null });
  //   setTimeout(() => this.setState({ groundOverlays: newOverlays, currentImageIndex: newOverlays.length - 1 }), 1);
  // }

  // const goToNextImage = () => {
  //   let newOverlays = this.state.groundOverlays;
  //   let index = newOverlays.length - 1;

  //   for (let i = 0; i < newOverlays.length; i++) {
  //     if (newOverlays[i].inUse) {
  //       index = i + 1;
  //     }

  //     newOverlays[i].inUse = false;
  //   }

  //   if (index >= newOverlays.length) {
  //     index = newOverlays.length - 1;
  //   }

  //   newOverlays[index].inUse = true;

  //   this.setState({ groundOverlays: null });
  //   setTimeout(() => this.setState({ groundOverlays: newOverlays, currentImageIndex: index }), 1);
  // }

  // const goToPreviousImage = () => {
  //   let newOverlays = this.state.groundOverlays;
  //   let index = newOverlays.length - 1;

  //   for (let i = 0; i < newOverlays.length; i++) {
  //     if (newOverlays[i].inUse) {
  //       index = i - 1;
  //     }

  //     newOverlays[i].inUse = false;
  //   }

  //   if (index < 0) {
  //     index = 0;
  //   }

  //   newOverlays[index].inUse = true;

  //   this.setState({ groundOverlays: null });
  //   setTimeout(() => this.setState({ groundOverlays: newOverlays, currentImageIndex: index }), 1);
  // }

  // const playPauseLoop = () => {
  //   if (this.state.playPauseIcon === 'PlayButton.jpg') {
  //     this.setState({ playPauseIcon: 'PauseButton.jpg' });
  //     this.loop(0);
  //   }
  //   else {
  //     this.setState({ playPauseIcon: 'PlayButton.jpg' });
  //     clearTimeout(this.loopTimer);
  //   }
  // }

  // const stopLoop = () => {
  //   clearTimeout(this.loopTimer);

  //   this.setState({ playPauseIcon: 'PlayButton.jpg' });
  //   this.goToLastImage();
  // }

  // const loop = (index) => {
  //   let newOverlays = this.state.groundOverlays;
  //   if (index < newOverlays.length) {
  //     for (let i = 0; i < newOverlays.length; i++) {
  //       newOverlays[i].inUse = false;
  //     }

  //     newOverlays[index].inUse = true;

  //     this.setState({ groundOverlays: null });
  //     setTimeout(() => this.setState({ groundOverlays: newOverlays, currentImageIndex: index }), 1);
  //     index++;

  //     if (index > newOverlays.length - 1) {
  //       this.loopTimer = setTimeout(() => {
  //         this.loop(0);
  //       }, 1000);
  //     }
  //     else {
  //       this.loopTimer = setTimeout(() => {
  //         this.loop(index);
  //       }, 250);
  //     }
  //   }
  //   else {
  //     for (let i = 0; i < newOverlays.length; i++) {
  //       newOverlays[i].inUse = false;
  //     }

  //     newOverlays[index].inUse = true;

  //     this.setState({ groundOverlays: null });
  //     setTimeout(() => this.setState({ groundOverlays: newOverlays, currentImageIndex: index }), 1);
  //   }
  // }

  // const getImageTime = () => {
  //   if (this.state.groundOverlays) {
  //     for (let i = 0; i < this.state.groundOverlays.length; i++) {
  //       if (this.state.groundOverlays[i].inUse) {
  //         return moment(this.state.groundOverlays[i].path.imageTime).tz('America/Denver').format('M/D/YYYY h:mm A z');
  //       }
  //     }
  //   }

  //   return ' ';
  // }

  return isLoaded ? (
    <div className="radar">
      <div>
        <GoogleMap
          mapContainerClassName="mapContainer"
          center={mapCenter}
          zoom={8}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
        </GoogleMap>
      </div>
    </div>
  ) : <></>
}

export default Radar;