import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import Highcharts from 'highcharts';
import { Dropdown } from 'primereact/dropdown';

import Griddle, { RowDefinition, ColumnDefinition } from 'griddle-react';
import ReactGA from 'react-ga';

import './yearlySummary.scss';
import { Chart, HighchartsChart, HighchartsProvider, LineSeries, Tooltip, XAxis, YAxis } from 'react-jsx-highcharts';
import { TEMPERATURE } from '../../Utils/constants';
import { YearlySummaryDailyValues, YearlySummaryHighLowValues, YearlySummaryResponse } from '../../models/yearly-summary-response';

const YearlySummary = () => {
  const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'));
  const [yearOptions, setYearOptions] = useState<string[]>([]);
  const [yearlyData, setYearlyData] = useState<YearlySummaryHighLowValues[]>([]);
  const [highLowChartData, setHighLowChartData] = useState<any[]>([]);

  useEffect(() => {
    const options: string[] = [];

    for (let i = parseInt(moment().format('YYYY'), 10); i >= 2004; i--) {
      options.push(i.toString());
    }

    setYearOptions(options);
  }, []);

  useEffect(() => {
    ReactGA.pageview('PastWeather/YearlySummary');

    const getYearlySummaryData = (year: string) => {
      axios.get<YearlySummaryResponse>(`${process.env.REACT_APP_PAST_WEATHER_CONTROLLER_ROOT}GetYearlySummaryData?year=${year}`)
        .then(response => {
          if (response.data) {
            setYearlyData(response.data.highLowValues);

            const chartData = [];
            const highs: number[][] = [];
            const lows: number[][] = [];
            response.data.highTemperatureValues.values.forEach((rec: YearlySummaryDailyValues) => {
              const time = moment.utc(rec.time).valueOf();
              highs.push([time, rec.value]);
            });

            response.data.lowTemperatureValues.values.forEach((rec: YearlySummaryDailyValues) => {
              const time = moment.utc(rec.time).valueOf();
              lows.push([time, rec.value]);
            });

            chartData.push({ data: highs, name: 'High Temperature', color: '#AC4F4C', yAxis: 'Temperature' });
            chartData.push({ data: lows, name: 'Low Temperature', color: '#0000FF', yAxis: 'Temperature' });

            setHighLowChartData(chartData);
          }
        });
    }

    if (selectedYear) {
      getYearlySummaryData(selectedYear);
    }
  }, [selectedYear]);

  const gridLayout = ({ Table }: any) => (
    <div>
      <Table />
    </div>
  );

  const yearChanged = (yearValue: string) => {
    ReactGA.event({
      category: 'Past Weather',
      action: 'Yearly Summary',
      label: 'Year Changed'
    });

    setSelectedYear(yearValue);
  }

  const weatherTypeHeading = ({ title }: any) => <div className='weatherTypeHeader'>{title}</div>;
  const valueHeading = ({ title }: any) => <div className='valueHeader'>{title}</div>;
  const miscHeading = ({ title }: any) => <div className='miscHeader'>{title}</div>;

  const alignRightCell = ({ value }: any) => <div className='alignRight'>{value}</div>;

  return (
    <div className='yearlySummary'>
      <div className='yearSelectContainer'>
        <div className='centeringDiv'>
          <div className='textContainer'>Go to </div>
          <Dropdown
            style={{ marginTop: '-3px' }}
            value={selectedYear}
            options={yearOptions}
            onChange={(e) => yearChanged(e.value)}
          />
        </div>
      </div>
      <div className='weatherDataContainer'>
        <div>
          <h2 style={{ textAlign: 'center' }}>{selectedYear}</h2>
        </div>
        <div className={'summaryContainer'}>
          <Griddle
            data={yearlyData}
            components={{ Layout: gridLayout }}>
            <RowDefinition>
              <ColumnDefinition id='weatherType' title='Weather Type' customHeadingComponent={weatherTypeHeading} />
              <ColumnDefinition id='displayValue' title='Value' customHeadingComponent={valueHeading} customComponent={alignRightCell} />
              <ColumnDefinition id='date' title='Date' customHeadingComponent={miscHeading} customComponent={alignRightCell} />
            </RowDefinition>
          </Griddle>
        </div>
        <div className="graphContainer">
          <HighchartsProvider Highcharts={Highcharts}>
            <HighchartsChart time={{ timezone: 'America/Denver' }}>
              <Chart height={210} width={470} />
              <Tooltip shared={true} />
              <XAxis
                type="datetime"
                labels={
                  {
                    style: {color: '#707073'}
                  }
                }
              />
              <YAxis id={TEMPERATURE}
                key={TEMPERATURE}
                labels={{
                  format: '{text}°',
                  style: {color: '#707073'}
                }}
                min={-30}
                max={110}
                startOnTick={false}>
                {highLowChartData.map((graph) => {
                  return <LineSeries
                    key={graph.name}
                    data={graph.data}
                    name={graph.name}
                    color={graph.color}
                    marker={{ enabled: false }}
                    yAxis={graph.yAxis} />;
                })}
              </YAxis>

            </HighchartsChart>
          </HighchartsProvider>
        </div>
      </div>
    </div>
  );
}

export default YearlySummary;