import React, { useState } from 'react';
import axios from 'axios';
import Highcharts from 'highcharts';
import {
  HighchartsChart, Chart, Tooltip, XAxis, YAxis, Title, HighchartsProvider, ColumnSeries, ColorAxis
} from 'react-jsx-highcharts';
import addTimelineModule from 'highcharts/modules/timeline';
import addColorAxis from 'highcharts/modules/coloraxis';
import { round } from '../Utils/utilities';
import './air-quality.css';
import './highcharts-dark-theme.scss';
import * as moment from 'moment';

addTimelineModule(Highcharts);
addColorAxis(Highcharts);

const AirQuality = (props) => {
    const [currentData, setCurrentData] = useState(null);
    const [airQualitySeries, setAirQualitySeries] = useState(null);

    const getColorValue = (aqi) => {
        if (aqi <= 12) {
            return 0;
        } else if (aqi <= 35.4) {
            return 4;
        } else if (aqi <= 55.4) {
            return 5;
        } else if (aqi <= 150) {
            return 8;
        } else {
            return 10;
        }
    }

    const loadInitialGraph = (chartRef) => {
        axios.get(`${process.env.REACT_APP_HOME_CONTROLLER_ROOT}GetAirQualityData`)
            .then ((response) => {
                if (response.data)
                {
                    const vals = [];
                    response.data.forEach((airQaulityRec) => {
                        const rec = round(airQaulityRec.pm25Standard, 1);
                        const md = moment.utc(airQaulityRec.recordDate);
                        const utcDate = moment.utc(md.format('YYYY-MM-DDTHH:mm')).valueOf();
                        vals.push({x: utcDate, y: rec, colorValue: getColorValue(rec)});
                    });

                    setAirQualitySeries(<ColumnSeries data={vals} name="Air Quality Index" colorKey="colorValue" />);

                    if (!currentData || currentData.recordDate !== response.data[response.data.length - 1].recordDate)
                    {
                        setCurrentData(response.data[response.data.length - 1]);
                    }
                }
            });
    } 

    return (
        <div className="air-quality">
            {currentData &&
            <div>
                <div className="row">
                    Record Time: {moment.utc(currentData.recordDate).local().format('MMM DD, YYYY h:mm A')}
                </div>
                <div className="row">
                    <div className="label">PM 1.0 Standard:</div>
                    <div className="data">{round(currentData.pm10Standard, 1)}</div>
                    <div className="label">PM 1.0 Env:</div>
                    <div className="data">{round(currentData.pm10Env, 1)}</div>
                </div>
                <div className="row">
                    <div className="label">PM 2.5 Standard:</div>
                    <div className="data">{round(currentData.pm25Standard, 1)}</div>
                    <div className="label">PM 2.5 Env:</div>
                    <div className="data">{round(currentData.pm25Env, 1)}</div>
                </div>
                <div className="row">
                    <div className="label">PM 10.0 Standard:</div>
                    <div className="data">{round(currentData.pm100Standard, 1)}</div>
                    <div className="label">PM 10.0 Env:</div>
                    <div className="data">{round(currentData.pm100Env, 1)}</div>
                </div>
                <div className="row">
                    <div className="label">Particles 03um:</div>
                    <div className="data">{round(currentData.particles03Um, 1)}</div>
                </div>
                <div className="row">
                    <div className="label">Particles 05um:</div>
                    <div className="data">{round(currentData.particles05Um, 1)}</div>
                </div>
                <div className="row">
                    <div className="label">Particles 10um:</div>
                    <div className="data">{round(currentData.particles10Um, 1)}</div>
                </div>
                <div className="row">
                    <div className="label">Particles 25um:</div>
                    <div className="data">{round(currentData.particles25Um, 1)}</div>
                </div>
                <div className="row">
                    <div className="label">Particles 50um:</div>
                    <div className="data">{round(currentData.particles50Um, 1)}</div>
                </div>
                <div className="row">
                    <div className="label">Particles 100um:</div>
                    <div className="data">{round(currentData.particles100Um, 1)}</div>
                </div>
                
            </div>
            }
            
            <div style={{clear: 'both'}}></div>
            <HighchartsProvider Highcharts={Highcharts}>
                <HighchartsChart callback={loadInitialGraph} time={{timezone: 'America/Denver'}}>
                    <Title>Air Quality Index</Title>
                    <Chart height={400} width={1000} />
                    <Tooltip />
                    <ColorAxis
                        stops={[[0, '#00ff00'], [0.5, '#ffff00'], [1, '#ff0000']]}
                        min={0}
                        max={10} />
                    <XAxis type="datetime" />

                    <YAxis id="airQualityIndex">
                        <Title>Air Quality Index</Title>
                        {airQualitySeries}
                    </YAxis>
                </HighchartsChart>
            </HighchartsProvider>
            
        </div>
    );
}

export default AirQuality;