/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import 'rc-select/assets/index.less'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import Records from './records';
import { CalendarData } from '../../models/calendar-data';
import { Calendar } from 'primereact/calendar';
import { Nullable } from 'primereact/ts-helpers';
import './calendar.scss';

const WeatherCalendar = () => {
  const [selectedDate, setSelectedDate] = useState<Nullable<Date>>(new Date());
  const [records, setRecords] = useState<CalendarData>();
  const [hideSelectedDayContainers, setHideSelectedDayContainers] = useState<string>('');

  const recordHeadings = {
    col1: 'Records',
    col2: 'Value',
    col3: 'Year'
  };

  const averageHeadings = {
    col1: 'Averages',
    col2: 'Value',
    col3: 'Years'
  };

  const dayHighHeadings = {
    col1: 'Selected Day Highs',
    col2: 'Value',
    col3: 'Time'
  };

  const dayLowHeadings = {
    col1: 'Selected Day Lows',
    col2: 'Value',
    col3: 'Time'
  };

  const dayAverageHeadings = {
    col1: 'Selected Day Averages',
    col2: 'Value',
    col3: ''
  };

  useEffect(() => {
    const getDayData = (day: string) => {
      axios.get<CalendarData>(process.env.REACT_APP_PAST_WEATHER_CONTROLLER_ROOT + 'GetCalendarDayData?recordDate=' + day)
        .then(response => {
          if (response.data) {
            setRecords(response.data);

            if (!response.data.highDayValues || response.data.highDayValues.length === 0) {
              setHideSelectedDayContainers(' hide');
            } else {
              setHideSelectedDayContainers('');
            }
          }
        });
    }

    getDayData(moment(selectedDate).format('MM-DD-YYYY'));
  }, [selectedDate]);

  return (
    <div className='weatherCalendar'>
      <div className='leftSideContainer'>
        <div className='calendarContainer'>
          <Calendar
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.value)}
            dateFormat="mm/dd/yyyy"
            minDate={new Date(2004, 7, 1)}
            inline
          />
        </div>
      </div>
      <div className='weatherDataContainer'>
        <h2>{moment(selectedDate).format('dddd, MMMM Do, YYYY')}</h2>
        <div className={'summaryContainer' + hideSelectedDayContainers}>
          <Records records={records?.highDayValues} columnHeadings={dayHighHeadings}></Records>
        </div>

        <div className={'summaryContainerCol2' + hideSelectedDayContainers}>
          <Records records={records?.lowDayValues} columnHeadings={dayLowHeadings}></Records>
        </div>

        <div className={'summaryContainerSelectedAverages' + hideSelectedDayContainers}>
          <Records records={records?.avgDayValues} columnHeadings={dayAverageHeadings}></Records>
        </div>

        <div className='clear'></div>
        <div className='summaryContainer row2'>
          <Records records={records?.records} columnHeadings={recordHeadings}></Records>
        </div>

        <div className='summaryContainerCol2 row2'>
          <Records records={records?.averages} columnHeadings={averageHeadings}></Records>
        </div>
      </div>
    </div>
  );
}

export { WeatherCalendar };