import { Menubar } from "primereact/menubar";
import { MenuItem } from "primereact/menuitem";
import './heading.scss';

const Heading = () => {
  const items: MenuItem[] = [
    {
      label: 'Home',
      icon: 'fa fa-home',
      url: '/'
    },
    {
      label: 'Radar',
      icon: 'fa fa-bullseye',
      url: '/Radar'
    },
    {
      label: 'Lightning',
      icon: 'fa fa-bolt',
      url: '/Lightning'
    },
    {
      label: 'Past Weather',
      icon: 'fa fa-reply',
      items: [
        {
          label: 'Calendar',
          icon: 'fa fa-calendar',
          url: '/PastWeather/Calendar'
        },
        {
          label: 'Monthly Summary',
          icon: 'fa fa-calendar',
          url: '/PastWeather/MonthlySummary'
        },
        {
          label: 'Yearly Summary',
          icon: 'fa fa-calendar',
          url: '/PastWeather/YearlySummary'
        },
        {
          label: 'Annual Climate Report',
          icon: 'fa fa-copy',
          url: '/PastWeather/AnnualClimateReport'
        }
      ]
    },
    {
      label: 'CWOP Weather',
      icon: 'fa fa-map-marker',
      url: '/CWOPWeather'
    },
  ];

  return (
    <header>
      <div className="header">
        <div className="title">Highlands Ranch Weather</div>

        <div className="menuContainer">
          <Menubar model={items} />
        </div>
      </div>

      <div className="clear"></div>
    </header>
  );
}

export default Heading;