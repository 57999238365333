import React, { useEffect, useState } from 'react';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import Axios from 'axios';
import moment from 'moment';
import { MapStation } from '../models/map-station';
import './cwop-weather.scss';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useLocation } from 'react-router-dom';

const CwopWeather = (props: any) => {
  let boundsTimeout: any;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAwLARifzAKBdUT5VGGBLrCN5nzuFA3UhY'
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [mapCenter, setMapCenter] = useState<any>({ lat: 39.52385, lng: -104.928233 });
  const [mapZoom, setMapZoom] = useState<number>(10);
  // const [markersOpen, setMarkersOpen] = useState<any[]>([]);
  const [stationMarkers, setStationMarkers] = useState<any[]>([]);
  const [stations, setStations] = useState<MapStation[]>([]);
  const [openMarker, setOpenMarker] = useState<string>('');
  const [searchedCallsign, setSearchedCallsign] = useState<string>('');
  const [infoWindowContent, setInfoWindowContent] = useState<any>();
  const [displayCurrentStations, setDisplayCurrentStations] = useState(true);
  const [displayRedStations, setDisplayRedStations] = useState(true);
  const [displayGrayStations, setDisplayGrayStations] = useState(false);
  const [location, setLocation] = useState('');
  
  const query = useLocation().search;

  useEffect(() => {
    const queryParams = new URLSearchParams(query);
    const latLon = queryParams.get('position');
    const zoom = queryParams.get('zoom') ? parseInt(queryParams.get('zoom')!, 10) : 10;
    
    if (latLon) {
      const splitlatLong = latLon.split(',');

      setMapCenter({lat: parseFloat(splitlatLong[0]), lng: parseFloat(splitlatLong[1])});
      setMapZoom(zoom);
    }
  }, [query]);

  const onLoad = (mapParam: google.maps.Map) => {
    setMap(mapParam);
  };

  const onUnmount = (mapParam: google.maps.Map) => {
    setMap(null)
  };

  const getWindDirection = (degrees: any) => {
    //N
    if (degrees >= 350 || degrees <= 11) {
      return 'N';
    }
    //NNE
    else if (degrees >= 12 && degrees <= 34) {
      return 'NNE';
    }
    //NE
    else if (degrees >= 35 && degrees <= 56) {
      return 'NE';
    }
    //ENE
    else if (degrees >= 57 && degrees <= 79) {
      return 'ENE';
    }
    //E
    else if (degrees >= 80 && degrees <= 101) {
      return 'E';
    }
    //ESE
    else if (degrees >= 102 && degrees <= 124) {
      return 'ESE';
    }
    //SE
    else if (degrees >= 125 && degrees <= 146) {
      return 'SE';
    }
    //SSE
    else if (degrees >= 147 && degrees <= 169) {
      return 'SSE';
    }
    //S
    else if (degrees >= 170 && degrees <= 191) {
      return 'S';
    }
    //SSW
    else if (degrees >= 192 && degrees <= 214) {
      return 'SSW';
    }
    //SW
    else if (degrees >= 215 && degrees <= 236) {
      return 'SW';
    }
    //WSW
    else if (degrees >= 237 && degrees <= 259) {
      return 'WSW';
    }
    //W
    else if (degrees >= 260 && degrees <= 281) {
      return 'W';
    }
    //WNW
    else if (degrees >= 282 && degrees <= 304) {
      return 'WNW';
    }
    //NW
    else if (degrees >= 305 && degrees <= 326) {
      return 'NW';
    }
    //NNW
    else if (degrees >= 327 && degrees <= 349) {
      return 'NNW';
    }
    else {
      return '---';
    }
  };

  const markerClick = (callSign: string) => {    
    setInfoWindowContent(
      <div className="marker-div">
        <img src={process.env.REACT_APP_IMAGE_DIR + 'WheelWait.gif'} alt='Loading Weather Data' title='Loading Weather Data' /> Loading Station Data
      </div>
    );
    setOpenMarker(callSign);

    Axios.get(`${process.env.REACT_APP_CWOP_CONTROLLER_ROOT}GetWeatherStationLastRecord?callSign=${callSign}`)
      .then(response => {
        const data = response.data;

        if (data) {
          const nowUtc = moment.utc();
          const lastRecDate = moment.utc(data.receiveDate);

          const diff = moment.duration(nowUtc.diff(lastRecDate));
          const days = diff.days();
          const hours = diff.hours();
          const mins = diff.minutes();
          const secs = diff.seconds();
          let lastUpdateString = '';

          if (days > 0) {
            lastUpdateString = days + ' Day';

            if (days > 1) {
              lastUpdateString += 's';
            }

            lastUpdateString += ' ';
          }

          if (hours > 0) {
            lastUpdateString += hours + ' Hour';

            if (hours > 1) {
              lastUpdateString += 's';
            }

            lastUpdateString += ' ';
          }

          if (mins > 0) {
            lastUpdateString += mins + ' Minute';

            if (mins > 1) {
              lastUpdateString += 's';
            }

            lastUpdateString += ' ';
          }

          if (secs > 0) {
            lastUpdateString += secs + ' Second';

            if (secs > 1) {
              lastUpdateString += 's';
            }

            lastUpdateString += ' ';
          }

          lastUpdateString += ' ago';

          setInfoWindowContent(<div className="marker-div">
              <div><span style={{ fontWeight: 'bold' }}>Call Sign: </span>{callSign}</div>
              <div><span style={{ fontWeight: 'bold' }}>Last Update: </span>{lastUpdateString}</div>
              <div><span style={{ fontWeight: 'bold' }}>Current Temperature: </span>{data.temperature != null ? data.temperature + '°F' : '---'}</div>
              <div><span style={{ fontWeight: 'bold' }}>Humidity: </span>{data.humidity != null ? data.humidity + '%' : '---'}</div>
              <div><span style={{ fontWeight: 'bold' }}>Avg Wind Speed: </span>{data.windSpeed != null ? data.windSpeed : '---'} mph</div>
              <div><span style={{ fontWeight: 'bold' }}>Wind Direction: </span>{data.windDirection != null ? data.windDirection + '° (' + getWindDirection(data.windDirection) + ')' : '---'}</div>
              <div><span style={{ fontWeight: 'bold' }}>Wind Gust: </span>{data.windGust != null ? data.windGust : '---'} mph</div>
              <div><span style={{ fontWeight: 'bold' }}>Last Hour Rainfall: </span>{data.rainLastHour != null ? data.rainLastHour : '---'} in.</div>
              <div><span style={{ fontWeight: 'bold' }}>Rainfall Today: </span>{data.rainToday != null ? data.rainToday : '---'} in.</div>
              <div><span style={{ fontWeight: 'bold' }}>Last 24 Hour Rainfall: </span>{data.rain24Hours != null ? data.rain24Hours + ' in.' : '---'}</div>
              <div><span style={{ fontWeight: 'bold' }}>Barometer: </span>{data.barometer != null ? (data.barometer / 33.8639).toFixed(3) : '---'} in.</div>
              <div><span style={{ fontWeight: 'bold' }}>Solar Radiation: </span>{data.solarRadiation != null ? data.solarRadiation : '---'} W/m<sup>2</sup></div>
              <div style={{marginTop: '1rem'}}><a href={'/CWOPWeather/station/' + callSign}>Station Page</a></div>
            </div>
          );
        } else {
          setInfoWindowContent(
            <div className="marker-div">
              No Data for the last 10 days
            </div>
          );
        }
      });
  };

  const onBoundsChanged = () => {
    clearTimeout(boundsTimeout);
    
    boundsTimeout = setTimeout(() => {
      const localBounds = map?.getBounds();
      const maxLon = localBounds?.getNorthEast().lng();
      const minLon = localBounds?.getSouthWest().lng();
      const maxLat = localBounds?.getNorthEast().lat();
      const minLat = localBounds?.getSouthWest().lat();
      // setMarkersOpen([]);

      Axios.get(`${process.env.REACT_APP_CWOP_CONTROLLER_ROOT}GetWeatherStations?maxLat=${maxLat}&minLat=${minLat}&maxLon=${maxLon}&minLon=${minLon}`)
        .then(response => {
          const data = response.data;
          const localStationMarkers: any[] = [];
          const localStations: MapStation[] = [];
          const localMarkersOpen: boolean[] = [];
          const utcNow = moment.utc();

          for (var i = 0; i < data.length; i++) {

            if (data[i].lastRecordDate === null) {
              data[i].icon = 'WXLogoGrey.png';
              data[i].tooltip = 'No Data in Last 10 Days';
            } else {
              const lastRecDate = moment.utc(data[i].lastRecordDate);
              const diff = utcNow.diff(lastRecDate, 'days', true);

              if (diff >= 10) //10 Days
              {
                data[i].icon = 'WXLogoGrey.png';
                data[i].tooltip = 'No Data in Last 10 Days';
              }
              else if (diff >= 1) //1 Day
              {
                data[i].icon = 'WXLogoRed.png';
                data[i].tooltip = 'Last Record Over 24 Hours Ago';
              }
              else {
                data[i].icon = 'WXLogo.png';
                data[i].tooltip = 'Data Received in Last 24 Hours';
              }
            }
            let callSign = data[i].callSign;

            localMarkersOpen[callSign] = true;

            localStationMarkers.push(data[i]);

            localStations.push({ callSign: data[i].callSign, icon: data[i].icon, tooltip: data[i].tooltip });
          }

          setStationMarkers([...localStationMarkers]);
          setStations([...localStations]);
          // setMarkersOpen([...localMarkersOpen]);
          setOpenMarker(searchedCallsign);
          setInfoWindowContent(searchedCallsign ?
            <div className="marker-div">
              <img src={process.env.REACT_APP_IMAGE_DIR + 'WheelWait.gif'} alt='Loading Weather Data' title='Loading Weather Data' /> Loading Station Data
            </div>
            :
            infoWindowContent);

          setSearchedCallsign('');
        });

    }, 500);
  };

  const updateDisplayStations = (e: any, stationType: 'blue' | 'red' | 'gray') => {
    if (stationType === 'blue') {
      setDisplayCurrentStations(e.target.checked);
    }
    else if (stationType === 'red') {
      setDisplayRedStations(e.target.checked);
    }
    else if (stationType === 'gray') {
      setDisplayGrayStations(e.target.checked);
    }
  };

  const goClick = () => {
    Axios.get(process.env.REACT_APP_CWOP_CONTROLLER_ROOT + 'GetStationLocation?callSign=' + location)
      .then(function (response) {

        if (response.data.x !== -999) {
          setSearchedCallsign(location.toUpperCase());
          setMapCenter({ lat: response.data.y, lng: response.data.x });
          markerClick(location);
        } else {
          const geocoder = new google.maps.Geocoder();

          geocoder.geocode({ 'address': location }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results) {
                setMapCenter({ lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() });
              }
            }
            else {
              alert('Geocode was not successful for the following reason: ' + status);
            }
          });
        }
      });
  };

  return isLoaded ? (
    <div className="cwop">
      <div>
        <div className='titleDiv'>
          <div className='searchFieldDiv'>
            <span style={{paddingRight: '.5rem'}}>Move Map To:</span>
            <InputText
              className="locationTextBox"
              type='text'
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              onKeyUp={(e) => console.log(e)}
              placeholder='Callsign, City, State, OR Zipcode'
            />
            <Button id='goButton' className='goButton' type='button' label="Go" onClick={() => goClick()}></Button>
            <div className='bookmarkDiv'>
              <a href={'/CWOPWeather?position=' + map?.getCenter()?.lat() + ',' + map?.getCenter()?.lng() + '&zoom=' + map?.getZoom()}>Go to Bookmarkable Page for this Position</a>
            </div>
          </div>
          <div className='clear'></div>
        </div>
        <div>
          <GoogleMap
            mapContainerClassName="mapContainer"
            center={mapCenter}
            zoom={mapZoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onBoundsChanged={onBoundsChanged}
          >
            {stationMarkers && stationMarkers.map((station: any) => {
              if ((station.icon === 'WXLogo.png' && displayCurrentStations)
                || (station.icon === 'WXLogoRed.png' && displayRedStations)
                || (station.icon === 'WXLogoGrey.png' && displayGrayStations)
              ) {
                return (
                  <Marker key={station.callSign}
                    position={{ lat: station.latitude, lng: station.longitude }}
                    icon={process.env.REACT_APP_IMAGE_DIR + station.icon}
                    onClick={() => markerClick(station.callSign)}
                  >
                    {openMarker === station.callSign &&
                      <InfoWindow onCloseClick={() => setOpenMarker('')}>
                        {infoWindowContent}
                      </InfoWindow>
                    }
                  </Marker>
                );
              }
              return null;
            }
            )}
            
          </GoogleMap>

          <div className='stationContainer'>
              <div className='header'>
                <div className='checkboxContainer'>
                  <input type='checkbox' checked={displayCurrentStations} onChange={(e) => { updateDisplayStations(e, 'blue'); }} />
                  <img src={process.env.REACT_APP_IMAGE_DIR + 'WXLogo.png'} title='Data Received in Last 24 Hours' alt='Data Received in Last 24 Hours' />
                </div>

                <div className='checkboxContainer'>
                  <input type='checkbox' checked={displayRedStations} onChange={(e) => { updateDisplayStations(e, 'red'); }} />
                  <img src={process.env.REACT_APP_IMAGE_DIR + 'WXLogoRed.png'} title='Data Received in More than 24 Hours Ago' alt='Data Received in More than 24 Hours Ago' />
                </div>

                <div className='checkboxContainer'>
                  <input type='checkbox' checked={displayGrayStations} onChange={(e) => { updateDisplayStations(e, 'gray'); }} />
                  <img src={process.env.REACT_APP_IMAGE_DIR + 'WXLogoGrey.png'} title='No Data Recieved for over 10 Days' alt='No Data Recieved for over 10 Days' />
                </div>
                <div className='clear'></div>
              </div>
              {stations && stations.map((station) => {
                if ((station.icon === 'WXLogo.png' && displayCurrentStations)
                  || (station.icon === 'WXLogoRed.png' && displayRedStations)
                  || (station.icon === 'WXLogoGrey.png' && displayGrayStations)
                ) {
                  return <div key={station.callSign + '_toc'} className="staionDiv" onClick={() => markerClick(station.callSign)}>
                    <img key={station.callSign + '_img'} src={process.env.REACT_APP_IMAGE_DIR + station.icon} title={station.tooltip} alt={station.tooltip} />&nbsp;
                    {station.callSign}
                  </div>;
                }
                return null;
              }
              )}
            </div>
        </div>
      </div>
    </div>
  ) : <></>
};

export default CwopWeather;
